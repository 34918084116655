import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the article matcher
 */
enum ArticleMatcherPermissionsEnum {
  None = 0,

  /**
   * Open matcher dialog and manage clinic to industry article matches.
   */
  ManageArticleMatches = 1,
}

export const ArticleMatcherPermissions = permissions(ArticleMatcherPermissionsEnum, {
  featureId: 'ArticleMatcher',
});
